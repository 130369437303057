import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PageHeader from "../../components/pageheader"
import Padding from "../../components/padding"
import styled from "styled-components"
import Layout from "../../components/layout"
import Stappen from "../../components/stappen"
import Stappen2 from "../../components/stappen2"
import SEO from "../../components/seo"
import { device } from "../../queries"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowAltCircleRight,faDotCircle, faCheck } from "@fortawesome/free-solid-svg-icons"
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'
import Img from "gatsby-image"


const Margin = styled.div`
  height: 60px;
`

const Styledcrumbs = styled(Breadcrumbs)`
    .MuiBreadcrumbs-separator{
        font-weight: 600;
    }
`
const Crumblink = styled(Link)`
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    color: var(--blue-primary);
    transition: all 0.25s cubic-bezier(.44,0,.17,1);
    &:hover{
        text-decoration: none;
        color: var(--blue-primary-h); 
    }
    &[aria-current="page"]{
        color: rgba(100,100,100);
        cursor: text;
        
        &:hover{
          

        }
    }
`

const Maincontent = styled.div`
    margin: 0 auto;
    max-width: 1200px;
    padding-top: 60px;
`

const Paragraph = styled.p`
    padding: 10px 0;
`

const Itemlist = styled.ul`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
    margin: 10px 0 0 0;
    li{
        font-weight: 400;
        width: 100%;
        padding: 5px 0;
        @media ${device.tablet}{
            width: 100%;
        }
    }
`

const StyledLiIcon = styled(FontAwesomeIcon)`
    color: var(--green-primary);
`

const Bigsub = styled.h2`
    font-weight: 700;
    font-size: 28px;
    color: var(--green-text);
`

const Subheader = styled.h2`
    font-weight: 700;
    font-size: 20px;
    color: var(--green-text);
`

const Containerpad = styled.div`
`
const Vertpad = styled.div`
    padding-bottom: 50px;
    @media ${device.laptopL}{
        padding: 0 20px 60px 20px;
    }

    @media ${device.mobileL}{
        padding: 0 15px 60px 15px;
    }
`


const Flexdivider = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    &>*{
        width: 48%;
        @media ${device.tablet}{
        width: 100%;
        &:last-of-type{
            padding-bottom: 0;
        }
        }

    }
    @media ${device.tablet}{
        flex-direction: column;
    }

`

const Checkgrid = styled.div`
    display: inline-grid;
    width: 100%;
    grid-template-columns: 50% 50%;
    gap: 15px 20px;
    padding: 10px 0;
    padding-top: 30px;
    @media ${device.tablet}{
        grid-template-columns: 100%;
    }


`
const Griditem = styled.div`
    display: flex;
    align-items: start;
    p{
        padding: 4px 10px;
    }

`

const Sbr = styled.br`

`

const Icoon = styled(FontAwesomeIcon)`
    font-size: 25px;
    display: inline-block;
    color: var(--green-primary);
`
function handleClick(event) {
    console.info("You clicked a breadcrumb.")
}

const Headerimage = ({something, className}) => {
    const data = useStaticQuery(graphql`
      query {
        placeholderImage: file(relativePath: { eq: "diensticonen/recycling.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        anotherImage: file(relativePath: { eq: "recycling/recycling.png" }) {
            childImageSharp {
              fluid(maxWidth: 2000 maxHeight: 200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        softdes: file(relativePath: { eq: "datavernietiging/softwarematige_dataverwijdering.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600 maxHeight: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mechdes: file(relativePath: { eq: "datavernietiging/mechanische_dataverwijdering.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 600 maxHeight: 200) {
                ...GatsbyImageSharpFluid
              }
            }
        }
      }
    `)
    const image = [<Img className={className} fluid={data.placeholderImage.childImageSharp.fluid} />,<Img className={className} fluid={data.anotherImage.childImageSharp.fluid}/>, <Img className={className} fluid={data.softdes.childImageSharp.fluid} />, <Img className={className} fluid={data.mechdes.childImageSharp.fluid} />]
    return image[something];
    }


const MedewerkerKlantenservice = () => (
    
  <Layout>
    <SEO title="Vactures" />
    <PageHeader title="Vacatures">
        <Styledcrumbs aria-label="breadcrumb">
        <Crumblink href="/" onClick={handleClick}>
            Home
          </Crumblink>
          <Crumblink href="/informatie" onClick={handleClick}>
            Informatie
          </Crumblink>
          <Crumblink href="/informatie/vacatures" onClick={handleClick}>
            Vacatures
          </Crumblink>
          <Crumblink aria-current="page" href="/informatie/vacatures/Medewerker Binnendienst" onClick={handleClick}>
            Medewerker Klantenservice
          </Crumblink>
        </Styledcrumbs>
    </PageHeader>
        <Maincontent>
            <Containerpad>
                <Vertpad>
                    <Paragraph>
                    <Bigsub>Vacature Medewerker Klantenservice</Bigsub>
                    </Paragraph>
                </Vertpad>
                <Vertpad>
                    <Subheader>Functieomschrijving</Subheader>
                    <Paragraph>
                    Green Digital is gespecialiseerd in de inkoop en verkoop van smartphones en ICT apparatuur.

Wij zijn per direct op zoek naar een medewerker klantenservice (32-40 uur).

Als medewerker klantenservice ga je met name uitgaande telefonische gesprekken aan met klanten en partners. De ICT is onze wereld en het is niet erg als dit nieuw voor je is, als je maar wilt leren. Je komt te werken in een klein team waar je volop ontwikkelingsmogelijkheden hebt.

                    </Paragraph>
                    <Paragraph>
            
                    Wij zoeken een toegewijde kandidaat die graag en gemakkelijk met klanten communiceert. Sta jij als starter aan het begin van je loonbaan of heb jij al wel relevante werk ervaring opgedaan en ben je toe aan een nieuwe uitdaging ? Dan kun je bij ons in een dynamische omgeving meegroeien en jezelf ontplooien.
            
                    </Paragraph>
                    <Paragraph>
                    <Paragraph />
                    <Paragraph />
                    <b>Wij vragen:</b><br/>
                      <Itemlist>
                                  <li><StyledLiIcon icon={faDotCircle} /> Enige ervaring in een vergelijkbare functie.<br/></li>
                                  <li><StyledLiIcon icon={faDotCircle} /> Ervaring in (zakelijke) communicatie en correspondentie.<br/></li>
                                  <li><StyledLiIcon icon={faDotCircle} /> Basiskennis van MS Office.</li>
                      </Itemlist>
                    </Paragraph>
                </Vertpad>
                <Vertpad>
                    <Paragraph>
                    <b>Wij bieden:</b><br/>
                      <Itemlist>
                                  <li><StyledLiIcon icon={faDotCircle} /> Werken bij een ambitieus en groeiend bedrijf met volop ontwikkelingsmogelijkheden.</li><br/>
                                  <li><StyledLiIcon icon={faDotCircle} /> Een salaris afhankelijk van opleiding en ervaring.</li><br/>
                                  <li><StyledLiIcon icon={faDotCircle} /> Reiskostenvergoeding.</li><br/>
                                  <li><StyledLiIcon icon={faDotCircle} /> Contract bepaalde tijd 6-12 maanden.</li>
                      </Itemlist>
                    </Paragraph>
                </Vertpad>
                <Vertpad>
                    <Paragraph>
                        <b>Secundaire arbeidsvoorwaarden:</b>
                        <Itemlist>
                                    <li><StyledLiIcon icon={faDotCircle} /> Flexibele werkuren</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Vakantiegeld</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Flexibele werkuren</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Bedrijfslaptop</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Reiskostenvergoeding</li>
                        </Itemlist>
                     
                    </Paragraph>
                    <Paragraph/>
                    <Paragraph/>
                    <Paragraph>
                        <b>Aanvullende betalingen:</b> Overuren uitbetaald.<br/><br/>
                        <b>Arbeidsvoorwaarden:</b> Reiskostenvergoeding.<br/><br/>
                        <b>Soort dienstverband:</b> Fulltime, Parttime, Stage, Bepaalde tijd.<br/><br/>
                        <b>Contractduur:</b> 6-12 maanden.<br/><br/>
                        <b>Parttime uren:</b> 32-40 per week.<br/><br/>
                        <b>Werkschema:</b> Dagdienst van maandag tot en met vrijdag.<br/><br/>
                        <b>Ervaring:</b> Klantenservice: 1 jaar (Gewenst).<br/><br/>
                        <b>Thuiswerken:</b> Nee.


                    </Paragraph>
                
                </Vertpad>
                
            </Containerpad>
        </Maincontent>
    <Padding/>
  </Layout> 
)

export default MedewerkerKlantenservice


